import { useRouteError } from "react-router-dom";
import Button from "../components/Button";
import { Link } from "react-router-dom";
import Logo from "./../assets/images/Logo.svg";
import Footer from "../Layout/Footer";
import style from "./404.module.css";
function Page404() {
   const error = useRouteError();
   console.log(error);
   return (
      <>
         <div className={style.wraper}>
            <div className={style.logoWraper}>
               <Link to={"/"} className={style.link}>
                  <div className={style.logo}>
                     <div className={style.logo_img}>
                        <img src={Logo} alt="logo" />
                     </div>
                     <div className={style.textLogo}>Turkusowa Wieża</div>
                  </div>
               </Link>
            </div>
            <h1 className={style.title}>
               <span>40</span>
               <span className={style.four}>4</span>
            </h1>
            <p className={style.text}>OOPS! Nie znaleziono strony</p>
            <p className={style.sub}>Wygląda na to, że nie ma tu tego, czego szukasz</p>
            <div className={style.btn}>
               <Button title={"Wróć na stronę główną"} to={"/"} />
            </div>
         </div>
      </>
   );
}
export default Page404;
