import style from "./Exams.module.css";
import { Link } from "react-router-dom";
import arrowLink from "./../assets/images/arrowLink.svg";

function ExamsPage() {
   return (
      <div className={style.wraper}>
         <Link to={"/"} className={style.link}>
            <div className={style.linkArrow}>
               <img src={arrowLink} alt="arrow left" />
            </div>
            Wróć do strony głównej
         </Link>
         <h1 className={style.title}>Wyniki egzaminów</h1>
         <h3 className={style.sub}>
            Szkoła podstawowa
            <div className={style.decor}></div>
         </h3>
         <h4 className={style.klasa}>Klasa 4</h4>
         <ul>
            <li>
               <a href="https://platforma.szkolawchmurze.org/my-exams/exam/12;is_exam=true ">
                  <span>Polski</span>
                  <span>https://platforma.szkolawchmurze.org/my-exams/exam/12;is_exam=true</span>
               </a>
            </li>
            <li>
               <a href="https://platforma.szkolawchmurze.org/my-exams/math-exam/2;is_exam=true ">
                  <span>Matematyka</span>
                  <span>https://platforma.szkolawchmurze.org/my-exams/math-exam/2;is_exam=true </span>
               </a>
            </li>
            <li>
               <a href="https://platforma.szkolawchmurze.org/my-exams/exam/21;is_exam=true ">
                  <span>Historia</span>
                  <span>https://platforma.szkolawchmurze.org/my-exams/exam/21;is_exam=true </span>
               </a>
            </li>
            <li>
               <a href=" https://platforma.szkolawchmurze.org/my-exams/exam/73;is_exam=true ">
                  <span>Informatyka</span>
                  <span> https://platforma.szkolawchmurze.org/my-exams/exam/73;is_exam=true </span>
               </a>
            </li>
            <li>
               <a href="https://platforma.szkolawchmurze.org/my-exams/exam/94;is_exam=true ">
                  <span>Angielski</span>
                  <span>https://platforma.szkolawchmurze.org/my-exams/exam/94;is_exam=true </span>
               </a>
            </li>
            <li>
               <a href="https://platforma.szkolawchmurze.org/my-exams/exam/13;is_exam=true ">
                  <span>Przyroda</span>
                  <span>https://platforma.szkolawchmurze.org/my-exams/exam/13;is_exam=true</span>
               </a>
            </li>
            <li>
               <a href="https://platforma.szkolawchmurze.org/my-exams/exam/87;is_exam=true  ">
                  <span>Religia</span>
                  <span>https://platforma.szkolawchmurze.org/my-exams/exam/87;is_exam=true </span>
               </a>
            </li>
         </ul>
         <h4 className={style.klasa}>Klasa 5</h4>
         <ul>
            <li>
               <a href="https://platforma.szkolawchmurze.org/my-exams/exam/10;is_exam=true ">
                  <span>Polski</span>
                  <span>https://platforma.szkolawchmurze.org/my-exams/exam/10;is_exam=true</span>
               </a>
            </li>
            <li>
               <a href="https://platforma.szkolawchmurze.org/my-exams/math-exam/3;is_exam=true  ">
                  <span>Matematyka</span>
                  <span>https://platforma.szkolawchmurze.org/my-exams/math-exam/3;is_exam=true </span>
               </a>
            </li>
            <li>
               <a href="https://platforma.szkolawchmurze.org/my-exams/exam/23;is_exam=true  ">
                  <span>Historia</span>
                  <span>https://platforma.szkolawchmurze.org/my-exams/exam/23;is_exam=true </span>
               </a>
            </li>
            <li>
               <a href="https://platforma.szkolawchmurze.org/my-exams/exam/77;is_exam=true ">
                  <span>Informatyka</span>
                  <span>https://platforma.szkolawchmurze.org/my-exams/exam/77;is_exam=true</span>
               </a>
            </li>
            <li>
               <a href="https://platforma.szkolawchmurze.org/my-exams/exam/95;is_exam=true">
                  <span>Angielski</span>
                  <span>https://platforma.szkolawchmurze.org/my-exams/exam/95;is_exam=true</span>
               </a>
            </li>
            <li>
               <a href="https://platforma.szkolawchmurze.org/my-exams/exam/8;is_exam=true  ">
                  <span>Biologia</span>
                  <span>https://platforma.szkolawchmurze.org/my-exams/exam/8;is_exam=true </span>
               </a>
            </li>
            <li>
               <a href="https://platforma.szkolawchmurze.org/my-exams/exam/9;is_exam=true ">
                  <span>Geografia</span>
                  <span>https://platforma.szkolawchmurze.org/my-exams/exam/9;is_exam=true</span>
               </a>
            </li>
            <li>
               <a href="https://platforma.szkolawchmurze.org/my-exams/exam/88;is_exam=true  ">
                  <span>Religia</span>
                  <span>https://platforma.szkolawchmurze.org/my-exams/exam/88;is_exam=true </span>
               </a>
            </li>
         </ul>
         <h4 className={style.klasa}>Klasa 6</h4>
         <ul>
            <li>
               <a href="https://platforma.szkolawchmurze.org/my-exams/exam/28;is_exam=true ">
                  <span>Polski</span>
                  <span>https://platforma.szkolawchmurze.org/my-exams/exam/28;is_exam=true</span>
               </a>
            </li>
            <li>
               <a href="https://platforma.szkolawchmurze.org/my-exams/math-exam/4;is_exam=true  ">
                  <span>Matematyka</span>
                  <span>https://platforma.szkolawchmurze.org/my-exams/math-exam/4;is_exam=true </span>
               </a>
            </li>
            <li>
               <a href="https://platforma.szkolawchmurze.org/my-exams/exam/27;is_exam=true  ">
                  <span>Historia</span>
                  <span>https://platforma.szkolawchmurze.org/my-exams/exam/27;is_exam=true </span>
               </a>
            </li>
            <li>
               <a href="https://platforma.szkolawchmurze.org/my-exams/exam/75;is_exam=true  ">
                  <span>Informatyka</span>
                  <span>https://platforma.szkolawchmurze.org/my-exams/exam/75;is_exam=true </span>
               </a>
            </li>
            <li>
               <a href="https://platforma.szkolawchmurze.org/my-exams/exam/96;is_exam=true ">
                  <span>Angielski</span>
                  <span>https://platforma.szkolawchmurze.org/my-exams/exam/96;is_exam=true</span>
               </a>
            </li>
            <li>
               <a href="https://platforma.szkolawchmurze.org/my-exams/exam/14;is_exam=true ">
                  <span>Biologia</span>
                  <span>https://platforma.szkolawchmurze.org/my-exams/exam/14;is_exam=true</span>
               </a>
            </li>
            <li>
               <a href="https://platforma.szkolawchmurze.org/my-exams/exam/11;is_exam=true ">
                  <span>Geografia</span>
                  <span>https://platforma.szkolawchmurze.org/my-exams/exam/11;is_exam=true</span>
               </a>
            </li>
            <li>
               <a href="https://platforma.szkolawchmurze.org/my-exams/exam/89;is_exam=true ">
                  <span>Religia</span>
                  <span>https://platforma.szkolawchmurze.org/my-exams/exam/89;is_exam=true</span>
               </a>
            </li>
         </ul>
         <h4 className={style.klasa}>Klasa 7</h4>
         <ul>
            <li>
               <a href="https://platforma.szkolawchmurze.org/my-exams/exam/15;is_exam=true ">
                  <span>Polski</span>
                  <span>https://platforma.szkolawchmurze.org/my-exams/exam/15;is_exam=true</span>
               </a>
            </li>
            <li>
               <a href="https://platforma.szkolawchmurze.org/my-exams/math-exam/5;is_exam=true ">
                  <span>Matematyka</span>
                  <span>https://platforma.szkolawchmurze.org/my-exams/math-exam/5;is_exam=true</span>
               </a>
            </li>
            <li>
               <a href="https://platforma.szkolawchmurze.org/my-exams/exam/18;is_exam=true  ">
                  <span>Historia</span>
                  <span>https://platforma.szkolawchmurze.org/my-exams/exam/18;is_exam=true </span>
               </a>
            </li>
            <li>
               <a href="https://platforma.szkolawchmurze.org/my-exams/exam/74;is_exam=true  ">
                  <span>Informatyka</span>
                  <span>https://platforma.szkolawchmurze.org/my-exams/exam/74;is_exam=true </span>
               </a>
            </li>
            <li>
               <a href="https://platforma.szkolawchmurze.org/my-exams/exam/97;is_exam=true ">
                  <span>Angielski</span>
                  <span>https://platforma.szkolawchmurze.org/my-exams/exam/97;is_exam=true</span>
               </a>
            </li>
            <li>
               <a href="https://platforma.szkolawchmurze.org/my-exams/exam/16;is_exam=true">
                  <span>Biologia</span>
                  <span>https://platforma.szkolawchmurze.org/my-exams/exam/16;is_exam=true</span>
               </a>
            </li>
            <li>
               <a href="https://platforma.szkolawchmurze.org/my-exams/exam/19;is_exam=true ">
                  <span>Geografia</span>
                  <span>https://platforma.szkolawchmurze.org/my-exams/exam/19;is_exam=true</span>
               </a>
            </li>
            <li>
               <a href="https://platforma.szkolawchmurze.org/my-exams/exam/45;is_exam=true">
                  <span>Fizyka</span>
                  <span>https://platforma.szkolawchmurze.org/my-exams/exam/45;is_exam=true</span>
               </a>
            </li>
            <li>
               <a href="https://platforma.szkolawchmurze.org/my-exams/exam/7;is_exam=true  ">
                  <span>Chemia</span>
                  <span>https://platforma.szkolawchmurze.org/my-exams/exam/7;is_exam=true </span>
               </a>
            </li>
            <li>
               <a href="https://platforma.szkolawchmurze.org/my-exams/exam/110;is_exam=true">
                  <span>Niemiecki</span>
                  <span>https://platforma.szkolawchmurze.org/my-exams/exam/110;is_exam=true</span>
               </a>
            </li>
            <li>
               <a href="https://platforma.szkolawchmurze.org/my-exams/exam/107;is_exam=true ">
                  <span>Francuski</span>
                  <span>https://platforma.szkolawchmurze.org/my-exams/exam/107;is_exam=true</span>
               </a>
            </li>
            <li>
               <a href="https://platforma.szkolawchmurze.org/my-exams/exam/104;is_exam=true  ">
                  <span>Hiszpański</span>
                  <span>https://platforma.szkolawchmurze.org/my-exams/exam/104;is_exam=true </span>
               </a>
            </li>
            <li>
               <a href="https://platforma.szkolawchmurze.org/my-exams/exam/116;is_exam=true ">
                  <span>Włoski</span>
                  <span>https://platforma.szkolawchmurze.org/my-exams/exam/116;is_exam=true</span>
               </a>
            </li>
            <li>
               <a href="https://platforma.szkolawchmurze.org/my-exams/exam/121;is_exam=true  ">
                  <span>Rosyjski</span>
                  <span>https://platforma.szkolawchmurze.org/my-exams/exam/121;is_exam=true</span>
               </a>
            </li>
            <li>
               <a href="https://platforma.szkolawchmurze.org/my-exams/exam/90;is_exam=true  ">
                  <span>Religia</span>
                  <span>https://platforma.szkolawchmurze.org/my-exams/exam/90;is_exam=true</span>
               </a>
            </li>
         </ul>
         <h4 className={style.klasa}>Klasa 8</h4>
         <ul>
            <li>
               <a href="https://platforma.szkolawchmurze.org/my-exams/exam/79;is_exam=true ">
                  <span>Polski</span>
                  <span>https://platforma.szkolawchmurze.org/my-exams/exam/79;is_exam=true</span>
               </a>
            </li>
            <li>
               <a href="https://platforma.szkolawchmurze.org/my-exams/math-exam/6;is_exam=true   ">
                  <span>Matematyka</span>
                  <span>https://platforma.szkolawchmurze.org/my-exams/math-exam/6;is_exam=true </span>
               </a>
            </li>
            <li>
               <a href="https://platforma.szkolawchmurze.org/my-exams/exam/29;is_exam=true ">
                  <span>Historia</span>
                  <span>https://platforma.szkolawchmurze.org/my-exams/exam/29;is_exam=true</span>
               </a>
            </li>
            <li>
               <a href="https://platforma.szkolawchmurze.org/my-exams/exam/76;is_exam=true">
                  <span>Informatyka</span>
                  <span>https://platforma.szkolawchmurze.org/my-exams/exam/76;is_exam=true</span>
               </a>
            </li>
            <li>
               <a href="https://platforma.szkolawchmurze.org/my-exams/exam/98;is_exam=true">
                  <span>Angielski</span>
                  <span>https://platforma.szkolawchmurze.org/my-exams/exam/98;is_exam=true</span>
               </a>
            </li>
            <li>
               <a href="https://platforma.szkolawchmurze.org/my-exams/exam/24;is_exam=true ">
                  <span>Biologia</span>
                  <span>https://platforma.szkolawchmurze.org/my-exams/exam/24;is_exam=true</span>
               </a>
            </li>
            <li>
               <a href="https://platforma.szkolawchmurze.org/my-exams/exam/25;is_exam=true">
                  <span>Geografia</span>
                  <span>https://platforma.szkolawchmurze.org/my-exams/exam/25;is_exam=true</span>
               </a>
            </li>
            <li>
               <a href="https://platforma.szkolawchmurze.org/my-exams/exam/48;is_exam=true ">
                  <span>Fizyka</span>
                  <span>https://platforma.szkolawchmurze.org/my-exams/exam/48;is_exam=true</span>
               </a>
            </li>
            <li>
               <a href="https://platforma.szkolawchmurze.org/my-exams/exam/22;is_exam=true ">
                  <span>Chemia</span>
                  <span>https://platforma.szkolawchmurze.org/my-exams/exam/22;is_exam=true</span>
               </a>
            </li>
            <li>
               <a href="https://platforma.szkolawchmurze.org/my-exams/exam/17;is_exam=true ">
                  <span>Wiedza o społeczeństwie</span>
                  <span>https://platforma.szkolawchmurze.org/my-exams/exam/17;is_exam=true </span>
               </a>
            </li>
            <li>
               <a href="https://platforma.szkolawchmurze.org/my-exams/exam/78;is_exam=true">
                  <span>Edukacja dla bezpieczeństwa</span>
                  <span>https://platforma.szkolawchmurze.org/my-exams/exam/78;is_exam=true</span>
               </a>
            </li>
            <li>
               <a href="https://platforma.szkolawchmurze.org/my-exams/exam/111;is_exam=true">
                  <span>Niemiecki</span>
                  <span>https://platforma.szkolawchmurze.org/my-exams/exam/111;is_exam=true</span>
               </a>
            </li>
            <li>
               <a href="https://platforma.szkolawchmurze.org/my-exams/exam/108;is_exam=true">
                  <span>Francuski</span>
                  <span>https://platforma.szkolawchmurze.org/my-exams/exam/108;is_exam=true</span>
               </a>
            </li>
            <li>
               <a href="https://platforma.szkolawchmurze.org/my-exams/exam/105;is_exam=true">
                  <span>Hiszpański</span>
                  <span>https://platforma.szkolawchmurze.org/my-exams/exam/105;is_exam=true</span>
               </a>
            </li>
            <li>
               <a href="https://platforma.szkolawchmurze.org/my-exams/exam/120;is_exam=true">
                  <span>Włoski</span>
                  <span>https://platforma.szkolawchmurze.org/my-exams/exam/120;is_exam=true</span>
               </a>
            </li>
            <li>
               <a href="https://platforma.szkolawchmurze.org/my-exams/exam/122;is_exam=true">
                  <span>Rosyjski</span>
                  <span>https://platforma.szkolawchmurze.org/my-exams/exam/122;is_exam=true</span>
               </a>
            </li>
            <li>
               <a href="https://platforma.szkolawchmurze.org/my-exams/exam/91;is_exam=true">
                  <span>Religia</span>
                  <span>https://platforma.szkolawchmurze.org/my-exams/exam/91;is_exam=true</span>
               </a>
            </li>
         </ul>
         {/* <h3 className={style.sub}>
            Liceum Ogólnokształcące
            <div className={style.decor}></div>
         </h3>
         <ul>
            <li>
               <a href="https://platforma.szkolawchmurze.org/my-exams/exam/33;is_exam=true">
                  <span>Polski</span>
                  <span>https://platforma.szkolawchmurze.org/my-exams/exam/33;is_exam=true</span>
               </a>
            </li>
            <li>
               <a href="https://platforma.szkolawchmurze.org/my-exams/math-exam/100;is_exam=true">
                  <span>Matematyka</span>
                  <span>https://platforma.szkolawchmurze.org/my-exams/math-exam/100;is_exam=true</span>
               </a>
            </li>
            <li>
               <a href="https://platforma.szkolawchmurze.org/my-exams/exam/71;is_exam=true">
                  <span>Historia</span>
                  <span>https://platforma.szkolawchmurze.org/my-exams/exam/71;is_exam=true</span>
               </a>
            </li>
            <li>
               <a href="https://platforma.szkolawchmurze.org/my-exams/exam/42;is_exam=true">
                  <span>Informatyka</span>
                  <span>https://platforma.szkolawchmurze.org/my-exams/exam/42;is_exam=true</span>
               </a>
            </li>
            <li>
               <a href="https://platforma.szkolawchmurze.org/my-exams/exam/99;is_exam=true">
                  <span>Angielski</span>
                  <span>https://platforma.szkolawchmurze.org/my-exams/exam/99;is_exam=true</span>
               </a>
            </li>
            <li>
               <a href="https://platforma.szkolawchmurze.org/my-exams/exam/43;is_exam=true">
                  <span>Biologia</span>
                  <span>https://platforma.szkolawchmurze.org/my-exams/exam/43;is_exam=true</span>
               </a>
            </li>
            <li>
               <a href="https://platforma.szkolawchmurze.org/my-exams/exam/68;is_exam=true">
                  <span>Geografia</span>
                  <span>https://platforma.szkolawchmurze.org/my-exams/exam/68;is_exam=true</span>
               </a>
            </li>
            <li>
               <a href="https://platforma.szkolawchmurze.org/my-exams/exam/32;is_exam=true">
                  <span>Fizyka</span>
                  <span>https://platforma.szkolawchmurze.org/my-exams/exam/32;is_exam=true</span>
               </a>
            </li>
            <li>
               <a href="https://platforma.szkolawchmurze.org/my-exams/exam/31;is_exam=true">
                  <span>Chemia</span>
                  <span>https://platforma.szkolawchmurze.org/my-exams/exam/31;is_exam=true</span>
               </a>
            </li>
            <li>
               <a href="https://platforma.szkolawchmurze.org/my-exams/exam/47;is_exam=true">
                  <span>Wiedza o społeczeństwie</span>
                  <span>https://platforma.szkolawchmurze.org/my-exams/exam/47;is_exam=true</span>
               </a>
            </li>
            <li>
               <a href="https://platforma.szkolawchmurze.org/my-exams/exam/41;is_exam=true">
                  <span>Filozofia</span>
                  <span>https://platforma.szkolawchmurze.org/my-exams/exam/41;is_exam=true</span>
               </a>
            </li>
            <li>
               <a href="https://platforma.szkolawchmurze.org/my-exams/exam/127;is_exam=true">
                  <span>Historia i Teraźniejszość</span>
                  <span>https://platforma.szkolawchmurze.org/my-exams/exam/127;is_exam=true</span>
               </a>
            </li>
            <li>
               <a href="https://platforma.szkolawchmurze.org/my-exams/exam/35;is_exam=true">
                  <span>Edukacja dla bezpieczeństwa</span>
                  <span>https://platforma.szkolawchmurze.org/my-exams/exam/35;is_exam=true</span>
               </a>
            </li>
            <li>
               <a href="https://platforma.szkolawchmurze.org/my-exams/exam/92;is_exam=true">
                  <span>Podstawy przedsiębiorczości</span>
                  <span>https://platforma.szkolawchmurze.org/my-exams/exam/92;is_exam=true</span>
               </a>
            </li>
            <li>
               <a href="https://platforma.szkolawchmurze.org/my-exams/exam/112;is_exam=true">
                  <span>Niemiecki</span>
                  <span>https://platforma.szkolawchmurze.org/my-exams/exam/112;is_exam=true</span>
               </a>
            </li>
            <li>
               <a href="https://platforma.szkolawchmurze.org/my-exams/exam/109;is_exam=true">
                  <span>Francuski</span>
                  <span>https://platforma.szkolawchmurze.org/my-exams/exam/109;is_exam=true</span>
               </a>
            </li>
            <li>
               <a href="https://platforma.szkolawchmurze.org/my-exams/exam/106;is_exam=true">
                  <span>Hiszpański</span>
                  <span>https://platforma.szkolawchmurze.org/my-exams/exam/106;is_exam=true</span>
               </a>
            </li>
            <li>
               <a href="https://platforma.szkolawchmurze.org/my-exams/exam/117;is_exam=true">
                  <span>Włoski</span>
                  <span>https://platforma.szkolawchmurze.org/my-exams/exam/117;is_exam=true</span>
               </a>
            </li>
            <li>
               <a href="https://platforma.szkolawchmurze.org/my-exams/exam/123;is_exam=true">
                  <span>Rosyjski</span>
                  <span>https://platforma.szkolawchmurze.org/my-exams/exam/123;is_exam=true</span>
               </a>
            </li>
            <li>
               <a href="https://platforma.szkolawchmurze.org/my-exams/exam/86;is_exam=true">
                  <span>Religia</span>
                  <span>https://platforma.szkolawchmurze.org/my-exams/exam/86;is_exam=true</span>
               </a>
            </li>
         </ul> */}
      </div>
   );
}
export default ExamsPage;
