import style from "./ButtonSecondary.module.css";

function ButtonSecondary(props) {
   return (
      <button className={style.button} onClick={props.scroll}>
         <span>{props.title}</span>
      </button>
   );
}
export default ButtonSecondary;
